.modal-body {
  padding: 5px 0;
}
.modal-content {
  border-radius: 0px;
}
.modal-header {
  background: #353535;
  padding: 13px;
  border-radius: 0px;
  border: none;
}
.modal-header .btn-close {
  color: white;
}
.scrollspy-example {
  height: 400px;
  overflow-y: scroll;
}
.accordion-item {
  border: 0;
}
.accordion-body {
  margin-top: 15px;
}
button.accordion-button:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
button.accordion-button {
  padding: 0;
}

.RadioInput[type="checkbox"]:checked + label {
  background-color: #b5b5b5;
  color: white;
}

.RadioLabel {
  text-align: center;
  padding: 2px 7px;
  margin-left: 2px;
  font-weight: 600;
  border: 1px solid #c6c6c6;
  color: #7d7d7d;
  border-radius: 6px;
  cursor: pointer;
}

.RadioInput {
  display: none;
}
.RadioInput {
  background-color: gray;
  color: white;
}
.RadioInput:checked + label {
  background-color: #b5b5b5;
  color: white;
}

ul.nav.nav-tabs .nav-link {
  width: 100%;
  border: 0px;
}
button.accordion-button.text-center {
  box-shadow: none;
  background: none;
}
ul.nav.nav-tabs .nav-link.active {
  width: 100%;
  border-bottom: 1px solid #353535 !important;
}
ul.nav.nav-tabs li {
  width: 50%;
}
ul.nav.nav-tabs {
  display: flex;
  justify-content: space-between;
}
.modal-title.h4 {
  background: transparent;
  font: normal normal 600 21px/30px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  text-align: justify;
}
.handleDrag {
  cursor: grab;
}
.setting-title {
  display: block;
  text-align: center;
}
.setting {
  padding: 20px;
  background-color: white;
}
.models-button {
  background: rgb(212, 212, 212);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.models-list {
  position: absolute;
  top: 0px;
  right: -350px;
  width: 400px;
  background: white;
  box-shadow: 2px 2px 5px #c5c5c5;
}
/******************** models styling ***********************/
nav ul {
  list-style: none;
  text-align: center;
  margin-bottom: 0px;
}
nav ul li {
  display: inline-block;
}
nav ul li a {
  position: relative;
  display: block;
  padding: 10px 5px;
  text-decoration: none;
  margin: 0 10px;
  color: black;
}
nav ul li a,
nav ul li a:after,
nav ul li a:before {
  transition: all 0.5s;
}

/* stroke */
nav.stroke-effect ul li a,
nav.fill-effect ul li a {
  position: relative;
}

nav.stroke-effect ul li a:after,
nav.fill-effect ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  background: black;
  height: 2px;
}
nav.stroke-effect ul li a:hover:after,
nav.stroke-effect ul li a.active:after {
  width: 100%;
}

nav.fill-effect ul li a {
  transition: all 2s;
}

nav.fill-effect ul li a:after {
  text-align: left;
  content: ".";
  margin: 0;
  opacity: 0;
}
nav.fill-effect ul li a:hover,
nav.fill-effect ul li a.active {
  color: #fff;
  z-index: 1;
}
nav.fill-effect ul li a:hover:after,
nav.fill-effect ul li a.active:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}

/* Circle */
nav.circle-effect ul li a {
  position: relative;
  overflow: hidden;

  z-index: 1;
}
nav.circle-effect ul li a:after {
  display: block;
  position: absolute;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ".";
  color: transparent;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  background: transparent;
}
nav.circle-effect ul li a:hover,
nav.circle-effect ul li a.active {
  color: white;
}
nav.circle-effect ul li a:hover:after,
nav.circle-effect ul li a.active:after {
  -webkit-animation: circle 1.5s ease-in forwards;
}

/* SHIFT */
nav.shift-effect ul li a {
  position: relative;
  z-index: 1;
}
nav.shift-effect ul li a:hover,
nav.shift-effect ul li a.active {
  color: white;
}
nav.shift-effect ul li a:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  content: ".";
  color: transparent;
  background: #000000;
  visibility: none;
  opacity: 0;
  z-index: -1;
}
nav.shift-effect ul li a:hover:after,
nav.shift-effect ul li a.active:after {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

nav.bordred-effect ul li a:before,
nav.bordred-effect ul li a:after {
  content: "";
  height: 1px;
  width: 0;
  opacity: 0;
  background-color: #000000;
  position: absolute;
  transition: all 0.5s;
}

nav.bordred-effect ul li a:before {
  top: 6px;
  left: -5px;
}

nav.bordred-effect ul li a:after {
  bottom: 5px;
  right: -5px;
}

nav.bordred-effect ul li a:hover:before,
nav.bordred-effect ul li a.active:before,
nav.bordred-effect ul li a:hover:after,
nav.bordred-effect ul li a.active:after {
  width: calc(100% + 20px);
  opacity: 0.7;
}

/* HOVER EFFECT 4 */

nav.catched-effect ul li a:before {
  content: "[";
  left: -5px;
}

nav.catched-effect ul li a:after {
  content: "]";
  right: -5px;
}

nav.catched-effect ul li a:before,
nav.catched-effect ul li a:after {
  position: absolute;
  opacity: 0;
  color: #000000;
  top: 10px;
  transition: all 0.5s;
}

nav.catched-effect ul li a:hover:before,
nav.catched-effect ul li a.active:before,
nav.catched-effect ul li a:hover:after,
nav.catched-effect ul li a.active:after {
  opacity: 0.7;
}

nav.catched-effect ul li a:hover:before,
nav.catched-effect ul li a.active:before {
  left: -5px;
}

.RadioInput[type="checkbox"]:checked + label {
  background-color: #b5b5b5;
  color: white;
}

.RadioLabel {
  text-align: center;
  padding: 2px 7px;
  margin-left: 2px;
  font-weight: 600;
  border: 1px solid #c6c6c6;
  color: #7d7d7d;
  border-radius: 6px;
  cursor: pointer;
}
div#Stylelien {
  max-height: 100%;
  overflow: visible;
}
.RadioInput {
  display: none;
}
.RadioInput {
  background-color: gray;
  color: white;
}
.RadioInput:checked + label {
  background-color: #b5b5b5;
  color: white;
}
nav.catched-effect ul li a:hover:after,
nav.catched-effect ul li a.active:after {
  right: -5px;
}

/* Keyframes */
@-webkit-keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }
  50% {
    width: 100%;
    height: 1px;
  }
  100% {
    width: 100%;
    height: 100%;
    background: #333;
  }
}

/* Keyframes */
@keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }
  50% {
    width: 100%;
    height: 1px;
  }
  100% {
    width: 100%;
    height: 100%;
    background: #333;
  }
}

/* Keyframes */
@-webkit-keyframes circle {
  0% {
    width: 1px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 1px;
    z-index: -1;
    background: #eee;
    border-radius: 100%;
  }
  100% {
    background: #aaa;
    height: 5000%;
    width: 5000%;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 0;
  }
} /* Keyframes */
@keyframes circle {
  0% {
    width: 1px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 1px;
    z-index: -1;
    background: rgb(125, 125, 125);
    border-radius: 100%;
  }
  100% {
    background: black;
    height: 5000%;
    width: 5000%;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 0;
  }
}
