#InfoParams span {
    text-align: left;
    color: #717171;
    font: normal normal normal 12px/15px Montserrat;
}
.addIcon i {
    color: #f6c344;
    font-size: 20px;
    cursor: pointer;
}
.deleteAdr i {
    color: #d52637;
    font-size: 17px;
    cursor: pointer;
}
.logo-img{
    width: 50px;
    display: block;
    margin: 10px auto;
}
span#btnAddImg {
    text-align: center;
}
.addIcon i.fa.fa-picture-o {
    text-align: right;
    float: right;
}
#InfoParams .inputfile + label {
	cursor: pointer; /* "hand" cursor */
}
#InfoParams .inputfile + label {
    font-weight: 700;
    display: inline-block;
    border: 0.5px dashed #B5B5B5;
    font-size: 14px;
    text-align: center;
    margin: 10px 0;
}
#InfoParams .add-new-field i {
    background: #f6c344;
    color: #fff;
    border-radius: 50px;
    padding: 4px;
    font-size: 12px;
    display: inline-block;
    vertical-align: middle;
}
#InfoParams .ingroupSocail {
    overflow: scroll;
    height: 364px;
}


#navSocial div.tab-pane {
    position: relative;
    left: 0;
    width: 100%;
    height: auto;
    margin: 5px auto;
}


.social-container {
    height: 100%;
    position: relative;
  }
  .social-container .social {
    background: #f6c344;
    color: #fff;
    border-radius: 50px;
    padding: 4px;
    font-size: 12px;
    display: inline-block;
    vertical-align: middle;
  }

  .social-container .social-drop {
    background: #353535;
    display: none;
    position: absolute;
    top: 31px;
    left: 0%;
    border-radius: 10px;
}
  .social-container .social-drop ul {
    margin-top: 2px;
    position: relative;
    display: block;
    margin: 0px auto;
    padding: 0;
}
  .social-container .social-drop li a {
    font-size: 20px;
    text-align: center;
    display: block;
    color: #fff;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
}
div#navSocial i {
    padding: 15px 12px;
}
 div#navSocial  .tw {
    color: #00aced;
  }
  div#navSocial .fb {
    color: #3b5998;
  }
  div#navSocial  .pin {
    color: #cb2027;
  }
  div#navSocial  .linkdin {
    color: #0077b5 ;
  }
  div#navSocial .insta {
    color: #8a3ab9 ;
  }
  div#navSocial  .whatsapp {
    color: #25D366  ;
  }
    div#navSocial  .vi {
    color: #353535 ;
  } 
   div#navSocial  .snap {
    color: #f6c344 ;
  }
    div#navSocial  .rss {
    color: #cc8d17 ;
  }
     div#navSocial  .youtube {
    color: #cd201f ;
  }
  