#coinImg .radius-input {
    text-align: center;
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #717171;
    opacity: 1;
    width: 40px;
    border-radius: 5px;
    box-shadow: none;
    margin: 0px 2px;
    max-width: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #71717175;
    opacity: 1;
}
#coinImg .blockTitle {
    font: normal normal normal 12px/15px Montserrat;
    color: white;
}
#coinImg .typeBorder input {
    display: none;
}
#coinImg .RadioInput[type="radio"]+label {
    cursor: pointer;
    border-radius: 5px;
}
#coinImg .btntype {
    position: relative;
    width: 26px;
    height: 26px;
    border: 1px solid #707070;
    border-radius: 5px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
}
#coinImg .RadioInput[type="radio"]:checked+label {
    box-shadow: none;
    background: #B5B5B5;
    color: white;
}