div#InfoRedirection button.btn.btn-rounded.redirection {
  background: #F6C344;
  text-align: center;
  font: normal normal bold 13px/16px Montserrat;
  letter-spacing: 0px;
  color: #fff;
  border-color: #F6C344;
  padding: 5px;
  margin: 0 2px;
}
div#InfoRedirection button.btn.btn-rounded.tablebtn {
  background: #f00404;
  text-align: center;
  font: normal normal bold 11px/13px Montserrat;
  letter-spacing: 0px;
  color: #fff;
  border-color: #f00404;
  padding: 5px;
  margin: 0 2px;
}
div#InfoRedirection button.btn.btn-rounded.reset {
  background: transparent;
  text-align: center;
  font: normal normal bold 13px/16px Montserrat;
  letter-spacing: 0px;
  color: #cbc9c9;
  border-color: #cbc9c9;
  padding: 5px;
}
div#InfoRedirection  label.labelIput {
  margin: 0 1%;
}
div#InfoRedirection .titreSource {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 11px;
}
div#InfoRedirection  .form-check-input:checked {
  background-color: #f6c344;
}
div#InfoRedirection span.test-aide {
  text-align: justify;
  font: normal normal 300 12px/14px Montserrat;
  letter-spacing: 0px;
  color: #717171;
  opacity: 1;
  vertical-align: middle;
  display: inline-block;
}

div#InfoRedirection input[type="radio" i] {
  visibility: initial;
}
div#InfoRedirection  .closeRetour {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B5B5B5;
  border-radius: 19px;
  opacity: 1;
  width: 106px;
  height: auto;
  padding: 0px 0px 0px;
  cursor: pointer;
  margin: 5px;
  display: flex;
  align-items: center;
  float: right;
}
div#InfoRedirection .closeRetour i {
  background: #CBC9C9 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50px;
  color: #fff;
  padding: 3px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(180deg);
  margin-top: 1px;
  padding: 10px;
  margin: 1px 13px 1px 2px;
}

#InfoRedirection th {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Montserrat';
  letter-spacing: 0px;
  color: #353535;
  opacity: 1;
  margin: 0 auto;
  text-align: center;
}

#InfoRedirection td {
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  font-family: 'Montserrat';
  letter-spacing: 0px;
  color: #353535;
  opacity: 1;
  margin: 0 auto;
  text-align: center;
}