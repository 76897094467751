#PopupItem {
    background: #F9F9F9 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 2px #00000029;
    opacity: 1;
    padding: 0px 13px;
    border-radius: 5px;
    cursor: grab;
}
#Popup span.messageMpdal {
    width: 195px;
    padding: 11px 0px;
    display: block;
    text-align: center;
    font: normal normal normal 14px/13px Montserrat;
    letter-spacing: 0px;
    color: #717171;
    opacity: 1;
}
#Popup .listPopup {
    margin: 0px auto 13px auto;
}
#PopupItem .SectionAdd {
    display: block;
    margin: 25px 18px 0px 0%;
    text-align: right;
}
#PopupItem i.fa-solid.fa-pager {
    color: #828282;
}
#PopupItem span.Parent {
    text-align: left;
    font: normal normal 300 14px/12px Montserrat;
    letter-spacing: 0px;
    color: #353535;
    opacity: 1;
    max-width: fit-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 6px
}
img.btn-closeModal {
    cursor: pointer;
    margin: -42px 23px 2px 0;
}
.is-dragging{
    cursor: grabbing;
}
ul.dropdown-menu.optionmodal {
    /* transform: none!important; */
    left: 107%!important;
    top: -45px!important;
    min-width: 191px;
    min-height: 109px;
    border: none;
    background-size: 100% 100%!important;
    box-shadow: 0px 0px 5px #80808057;
  }
  ul.dropdown-menu.optionmodal.show li {
    font: normal normal 300 12px/12px Montserrat;
    letter-spacing: 0px;
    color: #353535;
    opacity: 1;
    text-align: left;
    margin: 9px 20px 9px 20px;
    display: block;
    cursor: pointer;
  }
  ul.dropdown-menu.optionmodal.show:before {
    content: '';
    background: url("../../../assets//imgMenuSort/arrow-left.png")no-repeat!important;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 27px;
    right: 183px;
  }
  .SectionAdd {
    display: block;
    margin: 25px 18px 0px 0%;
    text-align: right;
}
ul.masquerOptions input {
    display: inline;
    visibility: hidden;
    width: 0px;
}
ul.masquerOptions label {
    display: inline-block;
    padding: 10px;
    width: 100%;
    cursor: pointer;
}
div#PopupItem .is-drag-active {
    background: red;
}
 #Popup select.form-select.title-selects {
    background: url("../../../assets/imgTitles/arrow-down.svg") no-repeat center right 10px ;
    cursor: pointer;
    margin: 18px auto;
    font: normal normal normal 12px/18px Montserrat;
    letter-spacing: 0px;
    color: #353535;
    opacity: 1;
    padding: 11px;
    border-bottom: 1px solid #ced4da;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
}
#Popup .rmsc .dropdown-heading {
    background: url("../../../assets/imgTitles/arrow-down.svg") no-repeat center right 10px ;
    cursor: pointer;
    margin: 0px auto;
    font: normal normal normal 12px/18px Montserrat;
    letter-spacing: 0px;
    color: #353535;
    opacity: 1;
    
}
#Popup .rmsc.multi-select {
    border-bottom: 1px solid #ced4da;
}


#Popup .rmsc .dropdown-container {
    border: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
}
#Popup button.clear-selected-button svg {
    margin-right: -3px;
    background: #aaa;
    color: #fff!important;
    border-radius: 50px;
    display: inline-block;
}
#Popup .rmsc .dropdown-heading svg.dropdown-heading-dropdown-arrow.gray {
    display: none;
}
.nestable-item-name {
    padding: 0px 17px 0 17px;
    margin-top: 12px;
}
#PopupItem li.masquerBtn:hover ul.masquerOptions:before{
    content: '';
    background: url("../../../assets//imgMenuSort/arrow-left.png")no-repeat!important;
    width: 20px;
    height: 30px;
    position: absolute;
    top: 14px;
    right: 191px;
    z-index: -1;
    transform: rotate(0deg);
}
#PopupItem li.masquerBtn:hover ul.masquerOptions {
    display: block;
    transform: translateX(148px);
}
#PopupItem li.masquerBtn ul.masquerOptions li.checkBox {
    margin: 0 auto;
}
#PopupItem ul.masquerOptions {
    position: absolute;
    transform: translate(148px, -40px)!important;
    min-width: 191px;
    background: #fff;
    min-height: 109px;
    padding: 0;
    border: none;
    background-size: 100% 100%!important;
    box-shadow: 0px 0px 5px #80808057;
    border-radius: 7px;
    display: none;
}
#PopupItem ul.masquerOptions li {
    padding: 0!important;
}
#PopupItem ul.masquerOptions i {
    margin-right: 5px;
    font-size: 17px;
    vertical-align: middle;
    text-align: center;
}
#PopupItem ul.dropdown-menu.optionmodal.show li {
    font: normal normal 300 12px/12px Montserrat;
    letter-spacing: 0px;
    color: #353535;
    opacity: 1;
    text-align: left;
    margin: 9px 20px 9px 20px;
    display: block;
    cursor: pointer;
}
#PopupItem  ul.masquerOptions label {
    display: inline-block;
    padding: 10px;
    cursor: pointer;
    font: normal normal 300 11px/11px Montserrat;
    letter-spacing: 0px;
    color: #353535;
    opacity: 1;
    text-align: left;
}
#PopupItem ul.dropdown-menu.optionmodal {
    /* transform: none!important; */
    left: 12%!important;
    top: -64px!important;
    min-width: 191px;
    min-height: 86px;
    border: none;
    background-size: 100% 100%!important;
    box-shadow: 0px 0px 5px #80808057;
}
#Popup button.btn-option {
    position: relative;
    padding: 7px 5px;
    background: transparent;
    border: none;
}
#Popup .titlechildren li.ItemMenu {
    padding: 13px 0px 1px 17px;
}
#Popup .titlechildren ul.MenuDrop {
    padding: 0;
}
#Popup ul.MenuDrop .option button {
    box-shadow: none;
    padding: 3px;
}
#Popup button.btnpage {
    background: #F6C344 0% 0% no-repeat padding-box;
    border-radius: 7px;
    border: none;
    padding: 9px;
    font: normal normal normal 12px/12px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    width: 182px;
}
#Popup .modal-header {
    min-height: 111px;
    background: #717171 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 15px #00000029;
    border-radius: 29px 29px 0px 0px;
}
#Popup span#exampleModalLabel {
    margin-left: 31px;
    background: transparent;
    text-align: justify;
    font: normal normal normal 14px/18px Montserrat;
    letter-spacing: 0px;
    color: #CBC9C9;
    opacity: 1;
}
#Popup .modal-content {
    border-radius: 31px;
}
.modal-backdrop.fade.show {
    display: none!important
}
#Popup  .modal-dialog.modal-lg {
    top: 14%;
}
#Popup  button.btn.btn-secondary {
    width: 140px;
    background: #B5B5B5 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    border: none;
    padding: 8px 0px;
}
#Popup button.btn.btn-primary {
    width: 140px;
    background: #F6C344 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    padding: 7px;
}

#Popup select.form-select.NamePage {
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    opacity: 1;
    margin: 11px auto;
}

#Popup select::placeholder {
    text-align: left;
    font: normal normal medium 10px/18px Montserrat;
    letter-spacing: 0px;
    color: #353535;
    opacity: 1;
  }

  .multiselect {
    width: 200px;
  }
  
  .selectBox {
    position: relative;
  }
  
  .selectBox select {
    width: 100%;
    font-weight: bold;
  }
  
  .overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  
  #checkboxes {
    display: none;
    border: 1px #dadada solid;
  }
  
  #checkboxes label {
    display: block;
  }
  
  #checkboxes label:hover {
    background-color: #1e90ff;
  }
  #Popup button.btn.btn-primary:disabled {
    background: rgb(246 195 68 / 42%) 0% 0% no-repeat padding-box;
    border-color: rgb(246 195 68 / 42%);
}
#Popup .nestable-item-name {
    overflow: scroll;
    height: 77vh;
}
div#PopupItem .pops {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}