

.group_password {
    position: relative;
    margin: 5% auto;
}
button.btn.btn-outline-primary {
    position: absolute;
    right: 10px;
    top: -3px;
    color: #fff;
    font-size: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
}
.sestion-login-1 {
    align-items: center;
    text-align: center;
    box-shadow: 0 0 2rem 0 #aaaaaa4d !important;
    background: #353535;
    max-width: 1200px !important;
    border-radius: 15px;
    float: none;
    height: 790px;
    /* width: 1200px; */
    background-position: top 0 right 0!important;
    background-size: cover!important;
    margin: 60px auto;
}
.title-auth span {
    margin-bottom: 10px;
    font: normal normal normal 14px/16px Montserrat;
    text-align: left;
    display: block;
}
span.footers {
    font: normal normal bold 10px/13px Montserrat;
    letter-spacing: 0px;
    color: #AAAAAA;
    margin-top: 3%;
    text-align: center;
}
.connecter {
    text-transform: none!important;
}
img.clicphones {
    margin-top: 31%;
}
img.auth.signin {
    border-radius: 20px;
    width: 324px;
    display: block;
    margin: 4% auto;
}
.sestion-login-1 .authentification {
    text-align: center;
    display: inline-block;
    background: #FFFFFF0D 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 28px;
    padding: 37px;
    width: 307px;
    height: 420px;
    margin: 32% 0% 28% auto;
}
button.btn.btn-google.btn-danger{
    width: 100%;
    margin: 0px 0px !important;
    background: #4385f4!important;
    font-size: 13px!important;
    font-weight: 300!important;
    padding: 0px!important;
    border: 1px solid #4385f4!important;
    border-radius: 5px!important;
    cursor: pointer!important;
    opacity: 1!important;
    box-shadow: none!important;
}
.icon-google img {
    width: 31px;
    height: 31px;
    display: block;
}
button.btn.btn-google.btn-danger span {
    font-weight: 300!important;
    padding: 0!important;
    text-align: center;
    font: normal normal normal 13px/14px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
}
button.btn.btn-google.btn-danger div {
    width: 31px;
    height: 31px;
    display: inline-block;
    vertical-align: text-top;
    float: left;
    padding: 7px!important;
    border-radius: 5px!important;
    margin: 1px 1px ;
}

button.btn.btn-google.btn-danger svg {
    align-items: center;
    justify-content: center;
    display: flex;
}
button.btn.btn-google.btn-danger span {
    font-weight: 300!important;
    padding: 0!important;
}
span.icon-google{
    padding: 1px 1px;
    align-items: center;
    border-radius: 3px;
    float: left;
    vertical-align: middle;
    display: inline-block;
}
.sestion-login-1 .authentification form {
    margin: 20px auto;
}
.navig {
    display: inline-block;
    margin-top: 5px;
    vertical-align: middle
}
span.footers {
    display: block;
    color: #AAAAAA;
    text-align: center;
    font: normal normal normal 10px/13px Montserrat;
    letter-spacing: 0px;
    margin-top: 0%
}
span.footers b {
    font: normal normal bold 10px/13px Montserrat;
}
button.btn.btn-outline-primary, button.btn.btn-outline-primary:hover {
    border: none;
    background: transparent;
}
.title-auth{
    text-align: center;
    font: normal normal normal 13px/15px Montserrat;
    letter-spacing: 0px;
    color: #AAAAAA;
    margin-bottom: 25px;
    margin-top: 12px;
    padding: 0px 31px 0 31px;
}
span.oublier {
    display: block;
    text-align: center;
    margin-top: 6%!important;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    font: normal normal bold 11px/12px Montserrat;
    letter-spacing: 0px;
    color: #F6C344;
}
.authentification button.connecter:disabled {
    background: rgb(246 195 68 / 46%);
    color: #fff;
    width: 100%;
}
.authentification button.connecter {
    width: 100%;
    background: #F6C344 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    border: none;
    text-align: center;
    font: normal normal normal 13px/14px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    padding: 11px;
    margin: 10% auto;
}
input::placeholder {
    /* text-align: center; */
    font: normal normal normal 10px/11px Montserrat;
    letter-spacing: 0px;
    color: #AAAAAA;
    opacity: 1;
}
img.auth.picture {
    width: 479px;
    margin: 5% auto;
}



.group_password .form-control:focus {
    color: #ffffff;
    background-color: #fff;
    background-color: #0000;
    border-color: #0000;
    outline: 0;
    box-shadow: none;
    border-bottom: 1px solid #ced4da;
    border-top: none;
    border-left: none;
    border-right: none;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}
.group_password .form-control{
    border-bottom: 1px solid #ced4da;
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: #0000!important;
    font-family: 'Montserrat';
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    font-size: 10px;
    padding: 15px;
    font-weight: 400;
    border-radius: 0;
   
}

/*==== FADE IN UP ===*/
@-webkit-keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
  }
  .animate {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  /*=== FADE IN DOWN ===*/

  /*==== FADE IN RIGHT ===*/
@-webkit-keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }
  @media(max-width:767px){
    .sestion-login-1{
        height: 1464px; 
    }
  }
  @media(min-width:1920px){
    .sestion-login-1{
        height: 790px; 
    }
}