.Breadcrumb div {
    margin: 18px auto;
}
.Breadcrumb img:hover {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #F6C344;
    transition: 0.5s;
}
.Breadcrumb img {
    width: 320px;
    border: 1px solid #cbc9c9;
}
.Breadcrumb::-webkit-scrollbar-thumb {
    background: #afafaf;
    border-radius: 10px;
    display: block!important;
    width: 9px;
}
#myTabContent::-webkit-scrollbar {
    display: block!important;
    width: 9px;
}

.Breadcrumb {
    height: 75%;
    overflow-x: hidden;
    overflow-y: scroll;
}
.Breadcrumb::-webkit-scrollbar-thumb {
    background: #afafaf;
    border-radius: 10px;
}
.Breadcrumb::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 5px grey;
}
.Breadcrumb::-webkit-scrollbar {
    display: block!important;
    width: 9px;
}
div.Breadcrumb {
    height: 75%;
    overflow-x: hidden;
    overflow-y: scroll;
}
#widgetsPanel .sectionBlocks {
    width: 95%;
    height: 150px;
    background-size: 100% 100%!important;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    border: 2px solid #4a4a4a;
    border-radius: 15px;
}
.Breadcrumb .gjs-blocks-c .gjs-block {
    width: 100%;
    margin: 3px auto!important;
    height: 150px!important;
    border: none!important;
    position: relative!important;

}
.Breadcrumb .gjs-blocks-c .gjs-block {
    width: 100%;
    margin: 3px auto;
    height: 100%;
    border: none;
}