
#blogsItem .modal-header {
    min-height: 111px;
    background: #717171 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 15px #00000029;
    border-radius: 10px 10px 0px 0px;
  }
  #blogsItem span#exampleModalLabel {
    margin-left: 31px;
    background: transparent;
    text-align: justify;
    font: normal normal normal 14px/18px Montserrat;
    letter-spacing: 0px;
    color: #cbc9c9;
    opacity: 1;
  }
  #blogsItem .modal-content {
    border-radius: 10px;
  }
  #blogsItem  .modal-backdrop.fade.show {
    display: none !important;
  }
  #blogsItem .modal-dialog.modal-lg {
    top: 14%;
  }
  #blogsItem button.btn.btn-secondary {
    width: 140px;
    background: #b5b5b5 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    border: none;
    padding: 8px 0px;
  }
  #blogsItem button.btn.btn-primary {
    width: 140px;
    background: #f6c344 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    padding: 7px;
  }
  

  #blogsItem .modal.show .form-control , #blogsItem .modal.show .form-select {
    border-bottom: 1px solid #ced4da;
    border-right: 0px;
    border-left: 0px;
    border-top: 0px;
    border-radius: 0px;
}

#blogsItem .galerie {
    height: 86px;
    position: relative;
    padding: 2px 0;
    background-color: #f2f2f2;
    color: #617379;
    font-size: 14px;
    border-radius: 3px;
    border: 1px dashed #617379;
    cursor: pointer;
    width: 86px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
}




#blogsItem div#collapseExample {
    position: absolute;
    width: 279px;
    height: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    transform: translate(576px, 58px);
    top: 290px!important;
    min-width: 191px;
    text-align: center;
    min-height: 109px;
    border: none;
    background-size: 100% 100%!important;
    box-shadow: 0px 0px 5px #80808057;
    border-radius: 8px;
}

#blogsItem  div#collapseExample:before {
    content: '';
    background: url("../../../assets/imgMenuSort/arrow-left.png")no-repeat!important;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 49px;
    right: 272px;
    transform: rotate(0deg);

  }
  #blogsItem  span.d-block.text-bold.mb-3 {
    display: none!important;
}

#blogsItem .hide {
    display: none;
}

#blogsItem .show {
    display: block;
}
div#AllBlogs {
  z-index: 3;
  position: absolute;
  max-width: 600px;
  min-width: 1053px;
  background: white;
  top: 50px;
  height: 94.6vh;
  overflow-y: clip;
  left: 82px;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 14%);
}
.SidebarWidgets div#AllBlogs div.tab-pane {
  box-shadow: none;
  background: none;
  position: relative;
  left: 0;
  width: auto;
  height: auto;
}
.SidebarWidgets div#AllBlogs div#v-pills-tab {
  display: block;
}

div#AllBlogs .SectionAdd {
  display: flex;
  justify-content: start;
  margin: 0 15px;
}

.SidebarWidgets div#AllBlogs .nav-pills .nav-link {
  text-align: left;
  font: normal normal normal 15px/16px Montserrat;
  letter-spacing: 0px;
  color: #353535;
  opacity: 1;
  background: transparent;
}

.SidebarWidgets div#AllBlogs .nav-pills .nav-link.active span, .SidebarWidgets div#AllBlogs .nav-pills .nav-link.active {
  text-align: left;
  font: normal normal normal 15px/16px Montserrat;
  letter-spacing: 0px;
  color: #F6C344;
  opacity: 1;
  background: transparent;
}
.SidebarWidgets div#AllBlogs div#v-pills-tabContent {
  width: 75%;
}