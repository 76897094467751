

button.connecter {
    background: #F6C344 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    width: 134px;
    text-align: center;
    font: normal normal normal 13px/14px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    padding: 10px;
    border: none;
    margin: 5% auto;
}
button.connecter:hover{
    background: #F6C344 0% 0% no-repeat padding-box;
    box-shadow: none;
    text-transform: none;
}
span.text-oublier {
    text-align: center;
    font: normal normal normal 14px/14px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    padding: 26px 50px;
    display: block;
}
button.annuler {
    text-align: center;
    font: normal normal normal 13px/14px Montserrat;
    letter-spacing: 0px;
    color: #F6C344;
    width: 134px;
    opacity: 1;
    background: transparent;
    box-shadow: none;
    text-transform: none;
    border: none;
}
.group_password .form-control:focus{
    color: #FFFFFF;
}
button.annuler:hover {
    background: transparent;
    box-shadow: none;
    text-transform: none;
}
.row.Oubiler{
    width: 361px;
margin: 0 auto;
}

.sestion-login-1.ob span.footers {
  font: normal normal normal 10px/13px Montserrat;
  letter-spacing: 0px;
  color: #AAAAAA;
  text-align: center;
  margin-top: 77px;
}
input::placeholder{
    font: normal normal normal 12px/13px Montserrat;
letter-spacing: 0px;
color: #AAAAAA;
opacity: 1;
}
.Oubiler .group_password {
    position: relative;
    margin-top: 9%;
}
span.text-oublier.first {
    padding: 0;
}
img.auth.signin.ob {
    margin: 0% auto 3% auto;
}
span.red.text-oublier {
    font: normal normal normal 14px/14px Montserrat;
    letter-spacing: 0px;
    color: #FF6D47;
    opacity: 1;
}
button.connecter:disabled {
    background: rgb(246 195 68 / 46%);
    
}

/*=== FADE IN LEFT ===*/
@-webkit-keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
  }
  
/*FadeInDown and FadeInLeft*/
/** fadeInDown **/
/*=== FADE IN DOWN ===*/
.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }
  @-webkit-keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @media (max-width: 767px){
    .sestion-login-1 {
      height: 790px;
  }
  }
  @media (min-width: 1920px){
    .sestion-login-1 {
      height: 790px;
  }
}