div#InfoExportZip button.btn.btn-rounded.export {
  background: #F6C344;
  text-align: center;
  font: normal normal bold 13px/16px Montserrat;
  letter-spacing: 0px;
  color: #fff;
  border-color: #F6C344;
  padding: 14px;
  margin: 8px auto;
  display: block;
}
div#InfoExportZip button.btn.btn-rounded.export:hover {
  background: #353535;
  border-color: #353535;
}
div#InfoExportZip button.btn.btn-rounded.reset {
  background: transparent;
  text-align: center;
  font: normal normal bold 13px/16px Montserrat;
  letter-spacing: 0px;
  color: #cbc9c9;
  border-color: #cbc9c9;
  padding: 5px;
}

div#InfoExportZip span.test-aide {
  text-align: justify;
  font: normal normal 300 12px/14px Montserrat;
  letter-spacing: 0px;
  color: #717171;
  opacity: 1;
  vertical-align: middle;
  display: inline-block;
}

div#InfoExportZip input[type="radio" i] {
  visibility: initial;
}
div#InfoExportZip  .closeRetour {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B5B5B5;
  border-radius: 19px;
  opacity: 1;
  width: 106px;
  height: auto;
  padding: 0px 0px 0px;
  cursor: pointer;
  margin: 5px;
  display: flex;
  align-items: center;
  float: right;
}
div#InfoExportZip .closeRetour i {
  background: #CBC9C9 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50px;
  color: #fff;
  padding: 3px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(180deg);
  margin-top: 1px;
  padding: 10px;
  margin: 1px 13px 1px 2px;
}

div#InfoExportZip .loader,
div#InfoExportZip .loader__fill,
div#InfoExportZip .loader__fill:before,
div#InfoExportZip .loader__fill-glow,
div#InfoExportZip .loader__middle,
div#InfoExportZip .loader__middle:before {
	border-radius: 50%;
}
div#InfoExportZip .loader, div#InfoExportZip .loader:after {
  border-radius: 50%;
  width: 3em;
  height: 3em;
}

div#InfoExportZip .loader {

    margin: 0 auto;
}
