div#InfoSeoSite button.btn.btn-rounded.send {
  background: #F6C344;
  text-align: center;
  font: normal normal bold 13px/16px Montserrat;
  letter-spacing: 0px;
  color: #fff;
  border-color: #F6C344;
  padding: 5px;
}
div#InfoSeoSite button.btn.btn-rounded.reset {
  background: transparent;
  text-align: center;
  font: normal normal bold 13px/16px Montserrat;
  letter-spacing: 0px;
  color: #cbc9c9;
  border-color: #cbc9c9;
  padding: 5px;
}
textarea#metatitle{
  min-height:250px;
}
textarea#metadesc{
  min-height:250px;
}
div#InfoSeoSite {
  overflow: hidden;
  overflow-y: scroll;
  max-height: 80vh;
}