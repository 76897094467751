#GererCategoerie span.titleblog {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 279px;
    display: block;
    font-weight: bold;
}
#GererCategoerie td {
    display: block!important;
    width: 100%;
    text-align: left;
}
#GererCategoerie button.btnblogs {
    background: #f6c344 0% 0% no-repeat padding-box;
    border-radius: 7px;
    border: none;
    padding: 9px;
    font: normal normal normal 12px/12px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    width:200px;
  }
  #GererCategoerie span.meta {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 6px 0;
    float: none;
    width: 37px;
    display: inline-block;
}
  #GererCategoerie .btnactions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
}
#GererCategoerie span.slogblog {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 367px;
    display: block;
    text-decoration: underline;
    font-size: 12px;
    color: #818181;
}
div#GererCategoerie tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid #eee;
}
#GererCategoerie .btnactions button {
    border: none;
    background: transparent;
    color: #cbc9c9;
    padding: 6px 4px;
}#GererCategoerie .btnactions button i {
 
    font-size: 14px;
}#GererCategoerie .btnactions button img {
    width: 15px;
}
#GererCategoerie table tbody {
    max-height: 77.4vh;
    overflow-y: auto;
    display: block;
}
.statuspublier {
    color: #616c79;
    border: 1px solid #616c79;
    border-radius: 6px;
    font-size: 12px;
    padding: 5px;
}
.etatstatusp {
    color: #0e9e67;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
}
.etatstatus {
    color: #616c79;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
}
.statusrepublier {
    color: #fff;
    border: 1px solid #f6c344;
    background: #f6c344;
    border-radius: 6px;
    font-size: 12px;
    padding: 5px;
}
div#GererCategoerie h1 {
    font-size: 20px;
}
div#GererCategoerie .input-group.serash-document {
    width: 50%;
    border-radius: 15px;
    color: white;
    margin: 0;
    
}
div#GererCategoerie  .input-group.serash-document input.form-control {
    border-radius: 6px!important
}
div#GererCategoerie table.tables {
    width: 100%;
}