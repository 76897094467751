#ContentGl .previewTitle{
    text-align: left;
font: italic normal 400 12px/13px Montserrat;
letter-spacing: 0px;

color: #717171;

}


#ContentGl .panelTitle {
    color: #717171;
    font: normal normal normal 12px/15px Montserrat;
}
#ContentGl .colorPreview {
    width: 30px;
    height: 30px;
    border: 1px solid gray;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
}

#ContentGl .FontSelect {
    width: 200px;
}
#ContentGl .input-small-pixel {
    border-radius: 10px;
    text-align: center;
    width: 77px;
}
#ContentGl .input-with-label-pixel {
    position: relative;
 
}
#ContentGl .form-control {
    border: 1px solid #b3b3b3
}
#ContentGl span.pixel-label {
    position: absolute;
    top: 5px;
    right: 23px;
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #717171;
    opacity: 1;
}

#ContentGl .RadioInput[type="checkbox"]:checked+label {
    background-color: #b5b5b5;
    color: white;
}

#ContentGl  .RadioLabel {
    text-align: center;
    padding: 2px 7px;
    margin-left: 2px;
    font-weight: 600;
    border: 1px solid #c6c6c6;
    color: #7d7d7d;
    border-radius: 6px;
    cursor: pointer;
}

#ContentGl  .RadioInput {
    display: none;
}
#ContentGl .RadioInput {
    background-color: gray;
    color: white;
}
#ContentGl .RadioInput:checked+label {
    background-color: #b5b5b5;
    color: white;
}
#ContentGl  span.color-value {
    width: 22px;
    height: 22px;
    display: block;
    margin: 3px;
    border-radius: 3px;
}
#ContentGl .css-1dodird-control:hover {
    border-color: #717171;
} 
#ContentGl .css-ckqi3r-control:hover {
    border-color: hsl(0, 0%, 70%);
}
.sketch-popeover {
    position: absolute;
    z-index: 2;
    right: 0;
}
.sketch-cover{
    position: fixed;
    top: 0px;
    right:0px;
    bottom: 0px;
    left: 0px;
}

.css-1dimb5e-singleValue{
    font: normal normal normal 12px/15px Montserrat;
}
.addIcon i.fa.fa-picture-o {
    text-align: right;
    float: right;
}
#ContentGl .inputfile + label {
	cursor: pointer; /* "hand" cursor */
}
#ContentGl .inputfile + label {
    font-weight: 700;
    display: inline-block;
    border: 0.5px dashed #B5B5B5;
    font-size: 14px;
    text-align: center;
    margin: 10px 10px;
}