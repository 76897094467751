#EditUser button.adM {
    border: 1px solid #dee2e6;
    background: #fff;
    color: #bdbdbd;
    width: 32px;
    height: 32px;
    border-radius: 5px;
}
#EditUser .itemGroupe {
    display: flex;
    margin: 10px auto;

}
#EditUser .IconGroupe span {
    background-color: white;
    font-size: 38px;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
}

#EditUser .suite .plus {
    margin-left: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 1s ease;
    display: none;
}

#EditUser .AllGroupe.container {
    position: relative;
}


#EditUser .suite:hover .plus {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    border-radius: 10px;
    background: white;
    transition: all 1s ease;
    border: none;
    box-shadow: 0px 0px 5px #eee;
    position: absolute;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 250px;
    margin-left: 44%;
}
#EditUser .suite span {
    display: inline;
    font-size: 14px;
    padding: 0 5px;
}
#EditUser span.titlegroupe {
    display: block;
    font-weight: 900;
}
#EditUser .contentGroupe {
    padding: 4px 0;
    border: 2px solid #eee;
    border-radius: 0px 10px 10px 0px;
    background: #fff;
    text-overflow: ellipsis;
    white-space: inherit;
    height: 102px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}
#EditUser .IconGroupe {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px 0px 0px 7px;
}
#EditUser button.updatebtn {
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 5px;
    font-size: 14px;
    width: 160px;
    background: #f6c344;
    color: #fff;
}