div#cellules .section {
    background: rgb(233 205 220);
       width:154px;
    height:52px;
}
div#cellules .previewTitle{
    text-align: left;
font: italic normal 400 12px/13px Montserrat;

letter-spacing: 0px;

color: #717171;

}
div#cellules .bg-section{
    display: inline-block;
    padding: 2px;
    border: 1px solid gray;
    background: white;

}
div#cellules .preview  {
    background: #E7F7F7!important;
    display: inline-flex;
    margin: 0 auto!important;

}
.SidebarWidgets #cellules .nav-pills .nav-link.active {
    background: transparent;
    padding: 6px;
    height: auto;
    font: normal normal normal 16px/24px Montserrat;
    border-top: 1px solid #eee;
    border-radius: 0;
    border-right: 0;
    border-left: 1px solid #eee;
    border-top-left-radius: 25px;
    border-bottom: none;
}
.SidebarWidgets #cellules .titleEspacement.s span {
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #717171;
    opacity: 1;
    display: block;
    text-align: left;
    margin-left: 6px;
}
.SidebarWidgets #cellules  .titleEspacement span {
    display: none;
}
.SidebarWidgets #cellules .nav-pills .nav-link {
    color: #353535;
    background: transparent;
    width: 176px;
    padding: 6px;
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
    border-top-right-radius: 0;
}
.SidebarWidgets div#cellules div.tab-pane {
    width: 100%;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: none;
    opacity: 1;
    position: relative;
    left: 0;
    z-index: 9;
}
div#cellules .GlobalCelulle {
    display: block;
    margin: 0 auto 40px auto;
    text-align: center;
    width: 244px;
    max-height: 91px;
    padding-top: 15px;
    background: url("../../../../assets/imgCellule/celluleBorder.png");
     background-size: 100% 100%;
}

div#cellules  #Espacement span.padding {
    position: absolute;
    top: -13px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 58px;
    font: normal normal normal 10px/13px Montserrat;
    letter-spacing: 0px;
    color: #717171;
    background: white 0% 0% no-repeat padding-box;

    color: #CBC9C9;
    opacity: 1;
    border-radius: 7px 8px 0px 0px;
    border: 1px solid #CBC9C9;
}
div#cellules #Espacement span.margin {
    position: absolute;
    top: -13px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 58px;
    background: white  0% 0% no-repeat padding-box;
    font: normal normal normal 10px/13px Montserrat;
    letter-spacing: 0px;
    color: #CBC9C9;

    opacity: 1;
    border-radius: 7px 8px 0px 0px;
    border: 1px solid #CBC9C9;
}