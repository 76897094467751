.imgPreview {
  width: 100%;
  /* 
    filter: blur(1.5px); */
}

.preview {
  border: 2px solid #353535;
  margin: 20px auto;
  border-radius: 5px;
}

.react-select__menu {
  z-index: 3;
}

select.categorySection {
  z-index: 2;
}

.Select-menu-outer {
  z-index: 999 !important;
}

input[type="radio"] + label {
  cursor: pointer;
}

.visibilite input[type="radio"] {
  visibility: hidden !important;
}
#ModalAddSection input[type="radio"]:checked + label {
  background-color: rgb(232, 202, 5) !important;
  color: black !important;
}
#preview {
  border: 1px solid gray;
  border-radius: 5px;
}
div#ModalAddSection .modal-body{
  padding: 5px 10px ;
}
#ModalAddSection .drop-zone {
  width: 100%;
  height: 200px;
  background-position: center;
  border: 2px dashed #717171;
  border-radius: 15px;
  opacity: 1;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
}
#ModalAddSection .modal-header {
  background: #2f373a;
  border-color: #2f373a;
  color: #fff;
}