#EspacementAR .titleEspacement span {
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #717171;
    opacity: 1;
    display: block;
    text-align: left;
    margin-left: 6px;
}
#EspacementAR div#marginblock {
    justify-content: space-around;
}
#EspacementAR .espacement_inputs {
    padding: 3px;
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border: 1px solid #B5B5B5;
    opacity: 1;
    border-radius: 14px 14px 0px 0px;
    width: 317px;
    height: 204px;
    margin: 40px auto 0 auto;
    position: relative;
}
#EspacementAR .spacing {
    width: 189px;
    height: 107px;
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border: 1px solid #B5B5B5;
    opacity: 1;
    border-radius: 4px;
    position: relative;
}
#EspacementAR div#marginblock:before, div#paddingblock:before {
    background: #eee;
    border-radius: 5px;
    color: #6c757d;
    line-height: 2px;
    padding: 0 6px;
    position: absolute;
    top: -1px;
}
#EspacementAR div#marginblock:before, div#paddingblock:before {
    background: #eee;
    border-radius: 5px;
    color: #6c757d;
    line-height: 2px;
    padding: 0 6px;
    position: absolute;
    top: -1px;
}

#EspacementAR .spacintInput {
    border-radius: 5px;
    box-shadow: none;
    margin: 11px 6px 21px 2px;
    max-width: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #71717175;
    opacity: 1;
}
#EspacementAR #marginblock input::placeholder {
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #717171;
    opacity: 1;
    text-align: center;
}

#EspacementAR .spacing-center-bottom{
    position: relative; 
}
#EspacementAR input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  #EspacementAR input[type=number] {
    text-align: center;
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #717171;
    opacity: 1;
    width: 40px;
}

#EspacementAR span.margin {
    position: absolute;
    top: -13px;
    left: 11px;
    width: 58px;
    background: #717171 0% 0% no-repeat padding-box;
    font: normal normal normal 10px/13px Montserrat;
    letter-spacing: 0px;
    color: #CBC9C9;
    opacity: 1;
    border-radius: 7px 8px 0px 0px;
    text-align: center;
}
#EspacementAR .paddingstype{
    position: relative;
}
#EspacementAR span.padding{
    position: absolute;
    top: -13px;
    left: 5px;
    width: 58px;
    background: #717171 0% 0% no-repeat padding-box;
    font: normal normal normal 10px/13px Montserrat;
    letter-spacing: 0px;
    color: #CBC9C9;
    opacity: 1;
    border-radius: 7px 8px 0px 0px;
    text-align: center;
}