.Slider div {
    margin: 18px auto;
}
.Slider img:hover {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #F6C344;
    transition: 0.5s;
}
.Slider img {
    width: 320px;
    border: 1px solid #00000029;
}
#widgetsPanel .sectionBlocks {
    width: 95%;
    height: 150px;
    background-size: 100% 100%!important;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    border: 2px solid #4a4a4a;
    border-radius: 15px;
}
.gjs-block-categories .gjs-block:hover {
    box-shadow: none;
}
.Slider .gjs-blocks-c .gjs-block {
    width: 100%;
    margin: 3px auto!important;
    height: 150px!important;
    border: none!important;
    position: relative!important;

}
.Slider .gjs-blocks-c .gjs-block {
    width: 100%;
    margin: 3px auto;
    height: 100%;
    border: none;
}