.modalAddMedia.modal.show .modal-dialog {
    max-width: 1180px;
}
.drop-zone span {
    text-align: center;
    font: normal normal normal 12px/25px Montserrat;
    letter-spacing: 0px;
    color: #B5B5B5;
    opacity: 1;
}
.drop-zone {
    width: 100%;
    height: 560px;
    background: #353535 url("../../assets/MediaLibrary/UploadFile.svg")no-repeat;
    background-position: center;
    border: 2px dashed #717171;
    border-radius: 15px;
    opacity: 1;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 235px;
}
#ModalAddImg .modal-title.h4 {
    text-align: left;
    font: normal normal normal 14px/18px Montserrat;
    letter-spacing: 0px;
    color: #353535;
    opacity: 1;
}
#ModalAddImg .modal-content {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 15px #00000029;
    border-radius: 0px;
    opacity: 1;
}
#ModalAddImg .modal-body {
    padding: 35px 10px 11px 10px;
}
#ModalAddImg .modal-header {
  color:#1b1b1b!important;
}

.image-tabs {
    width: 100%;
    margin: 0px;
    display: flex;
    justify-content: space-between;
}
.ListeImage {
    display: flex;
    flex-wrap: wrap;
}
.searchImage{
    margin-bottom: 15px;
}
.detailleImg {
    background: #353535 0% 0% no-repeat padding-box;
    margin: 2px;
    width: 24%;
    height: 140px;
    position: relative;
    cursor: pointer;
}
.labelImg{
    display: block;
    width: 100%;
    height: 100%;
}
.downloadOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    background: #353535f0;
}
.detailleImg:hover .downloadOverlay {
    display: flex;
}
.listImage{
    overflow-y: scroll;
    max-height: 460px;
}
.Img {
    width: 145px;
    display: inline-block;
    border: 1px solid #707070;
    padding: 0px;
    margin: 3px 3px 4px 3px;
    vertical-align: bottom;
}
.informationImg {
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 5px;
}
.image-tabs button:hover {

    color: #1b1b1b;
}
.image-tabs button {
    background-color: transparent!important;
    border-bottom: 2px solid #353535!important;
    border-radius: 0px!important;
    margin: 0px 2px;
    color: #1b1b1b;
}
.image-tabs button.active {
color:#f6c344!important;
    border-bottom: 2px solid #f6c344!important;
 
}
.detailleImg:hover .informationImg {
    display: block;
    background: #000000c2;
    color: #f6c344;}
.informationImg span {
    display: block;
    font: normal normal normal 12px/25px Montserrat;
    letter-spacing: 0px;
    color: #B5B5B5;
    opacity: 1;
}
input[type="radio" i]{
    visibility: hidden;
}
.progressBar{
  
    padding: 40px 20px;
}
.detailleImg i.fa.fa-trash {
    color: #fff;
    margin: 0;
    text-align: right;
    display: inline-block;
}
.checkedImg {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: rgb(53 53 53 / 83%);
    color: #f6c344;
    font-size: 35px;
}
input.MediaInput {
    display: none!important;
}
.deleteImg {
    display: none;
    background: #B7472A 0% 0% no-repeat padding-box;
    border-radius: 3px;
    margin: 0;
    right: 2px;
    padding: 3px;
    position: absolute;
    top: 5px;
}
.detailleImg:hover .deleteImg {
    display:block;
    cursor: pointer;
}
.ModShowImg {
    padding-bottom: 15px;
}