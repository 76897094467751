#EditorTopbar {
  background: #353535 0% 0% no-repeat padding-box;
  height: 50px;
  color: white;
}
#EditorTopbar .logo {
  width: 90px;
}
#EditorTopbar .pageChooser {
  display: block;
  width: 391px;
  padding: 5px;
}
#EditorTopbar .logo img {
  padding: 5px;
}
#EditorTopbar .homeLink {
  margin-right: 5px;
  color: white;
}
#EditorTopbar .siteName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#EditorTopbar .publier button {
  font-size: 12px;
}
#EditorTopbar .publier {
  margin-top: -7px;
  background: #f6c344;
  border-radius: 15px;
}
#EditorTopbar .fade.modalDelete.modal.show {
  background: rgb(19 19 19 / 30%);
}
#EditorTopbar .css-qc6sy-singleValue {
  color: white;
  text-align: left;
  margin-right: 35px;
  min-width: 100px;
}
#EditorTopbar span.select__indicator-separator.css-1okebmr-indicatorSeparator {
  display: none;
}
#EditorTopbar .select__control.css-1s2u09g-control {
  background-color: #353535;
  border: none;
}
#EditorTopbar .css-1pahdxg-control {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

#EditorTopbar svg.css-tj5bde-Svg {
  background: #ffffff1a 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 20px;
  height: 20px;
  border-radius: 50px;
}
#EditorTopbar #react-select-3-listbox {
  background-color: #353535 !important;
  z-index: 999999;
}
#EditorTopbar .border-right {
  border-right: 1px solid #707070;
  box-shadow: 1px -1px #000000;
}
#EditorTopbar .devices {
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
}
#EditorTopbar .devices .boutton {
  cursor: pointer;
  margin: 0px 10px;
}
#EditorTopbar .devices i {
  font-size: 25px;
  line-height: 33px;
  color: #cbc9c9;
}
#EditorTopbar .boutton.active i {
  color: #f6c344 !important;
}
#EditorTopbar .actions-btns div {
  cursor: pointer;
}
#EditorTopbar .actions-btns div.active a i {
  color: #f6c344 !important;
}
#EditorTopbar .actions-btns i {
  color: #cbc9c9;
}
#EditorTopbar i.annim-rotate {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
#EditorTopbar i.green {
  color: green !important;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
optgroup label {
  visibility: hidden;
  height: 0;
  display: none;
}
optgroup option {
  display: block;
}

.styled-select select {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
select.form-select.selectMenu option {
  background: #353535;
  text-align: center;
  color: #fff;
  text-overflow: ellipsis;
  width: 20px;
  overflow: hidden;
}

#EditorTopbar button.gjs-btn-prim.gjs-btn-import {
  background: #f6c344;
  border-radius: 15px;
  margin: 10px;
  color: #353535;
  font: normal normal normal 14px/21px Montserrat;
  letter-spacing: 0px;
  padding: 3px 14px 3px 14px;
}
select.form-select.selectMenu {
  color: #fff;
  background-color: #353535;
  background-image: url("../../assets/imgEditorTop/imgSelectPage.png") !important;
  background-size: auto;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 10px 38px 10px 0;
  font-size: 14px;
  line-height: 18px;
  font-family: "Montserrat";
  text-overflow: ellipsis;
  width: 150px;
  display: block;
}
select.form-select.selectMenu option:hover {
  background-color: yellow !important;
}
#EditorTopbar .actions-btns {
  margin-top: -5px;
}

#publier button.btn.btn-rounded.btn.btn-outline-secondary.publier {
  background: #f6c344;
  text-align: center;
  font: normal normal bold 13px/16px Montserrat;
  letter-spacing: 0px;
  color: #fff;
  border-color: #f6c344;
}
#editCode {
  max-width: 100%;
}
#editCode .aceEditor {
  width: 50%;
}
