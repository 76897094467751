#Titles select.title-select {
    background: url("../../../../assets/imgTitles/arrow-down.svg") no-repeat center right 10px ;
    cursor: pointer;
    width: 355px;
    margin: 0px 20px;
}
#Titles .previewTitle{
    text-align: left;
font: italic normal 400 12px/13px Montserrat;
letter-spacing: 0px;

color: #717171;

}


#Titles .colorPreview {
    width: 30px;
    height: 30px;
    border: 1px solid gray;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
}
#Titles .sketch-picker.active {
    display: block;
}
#Titles .FontSelect {
    width: 200px;
}
#Titles .input-small-pixel {
   border-radius:10px;
   text-align: center;
   width: 77px;
}
#Titles .input-with-label-pixel {
    position: relative;
 
}
#Titles .form-control {
    border: 1px solid #b3b3b3
}
#Titles span.pixel-label {
    position: absolute;
    top: 5px;
    right: 23px;
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #717171;
    opacity: 1;
}

#Titles .RadioInput[type="checkbox"]:checked+label {
    background-color: #b5b5b5;
    color: white;
}

#Titles  .RadioLabel {
    text-align: center;
    padding: 2px 7px;
    margin-left: 2px;
    font-weight: 600;
    border: 1px solid #c6c6c6;
    color: #7d7d7d;
    border-radius: 6px;
    cursor: pointer;
}

#Titles  .RadioInput {
    display: none;
}
#Titles .RadioInput {
    background-color: gray;
    color: white;
}
#Titles .RadioInput:checked+label {
    background-color: #b5b5b5;
    color: white;
}
#Titles  span.color-value {
    width: 22px;
    height: 22px;
    display: block;
    margin: 3px;
    border-radius: 3px;
}
#Titles .css-1dodird-control:hover {
    border-color: #717171;
} 
#Titles .css-ckqi3r-control:hover {
    border-color: hsl(0, 0%, 70%);
}
#Titles .panelTitle {
    color: #717171;
    font: normal normal normal 12px/15px Montserrat;
}