
  #OmbreColonne .typeOmbre input{
display:none
}
#OmbreColonne .typeOmbre{
    position: relative;
    
}
#OmbreColonne .btntype {
position: relative;
    width: 26px;
    height: 26px;
    border: 1px solid #707070;
    border-radius: 5px;

}
#OmbreColonne .btntype.insettype:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    height: 18px;
    width: 18px;
    box-shadow: inset 0px 0px 5px grey;
    border-radius: 5px;
}
#OmbreColonne .titleSize {
    font: normal normal normal 12px/15px Montserrat;
}
#OmbreColonne   #OmbreColonne label.form-check-label {
    font: normal normal normal 12px/15px Montserrat;
}
#OmbreColonne .btntype.outsettype:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    height: 18px;
    width: 18px;
    box-shadow:  0px 0px 5px grey;
    border-radius: 5px;
}
#OmbreColonne .RadioInput[type="radio"]+label {
    cursor: pointer;;
    
    border-radius: 5px;

    }
    #OmbreColonne .RadioInput[type="radio"]:checked+label {
        box-shadow: none;
        background: #B5B5B5;
    }
    #OmbreColonne .input-with-label-pixel {
    position: relative;
 
}
#OmbreColonne  .input-small-pixel {
    border-radius:10px;
    text-align: center;

 }
 #OmbreColonne  span.pixel-label {
    
    position: absolute;
    top: 5px;
    right: 20px;
    font-weight: 400;
}
#OmbreColonne  input.rangeInput.me-2 {
    width: 85px;
    height: 2px;
}
#OmbreColonne  .input-small-pixel {
    border-radius: 10px;
    text-align: center;
    padding-left: 0;
    width: 70px;
}
#OmbreColonne .rangeInput{
    background: linear-gradient(to right, #707070 0%, #707070 40%, #fff 40%, #fff 100%);
    border: solid 2px #707070;
    border-radius: 8px;
    height: 7px;
    width: 400px;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
  }
  #OmbreColonne .rangeInput::-webkit-slider-thumb {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    -webkit-appearance: none;
    cursor: ew-resize;
    background: white;
    border:1px solid #707070
  }
