div#searchlist button.accordion-button, div#searchlist .accordion-item, div#searchlist .accordion-button:not(.collapsed) {
    background: white;
    border: none;
    box-shadow: none;
    color: #000!important;
}
div#searchlist .nameGroupe {
    color: #353535;
    font-weight: bold;
}
div#searchlist .etatGroupe {
    color: #353535;
}
div#searchlist  button.accordion-button {
    padding: 10px;
}
div#searchlist .teamitem {
    border-radius: 3px;
    border: 1px solid #e4e4e4;
    background-color: white;
    padding: 5px 10px;
    position: relative;
}
div#searchlist .accordion-body {
    margin-top: 0;
    background: #f9f9f9;
    padding: 10px 10px;
}

div#searchlist .iconteams {
    font-size: 19px;
    color: white;
    border-radius: 26px;
    top: -2px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
div#searchlist div.actionteam {
    border: none;
}
div#searchlist .orange{
    background: #fd7122;
}div#searchlist .blue{
    background: #15c1bf;
}div#searchlist .violet{
    background: #ba80ba;
}div#searchlist .yellow{
    background: #fbba00;
}
div#searchlist div {
    border: 1px solid #c1b7b7;
    border-radius: 5px;
    background: #fff;
    padding: 15px;
}