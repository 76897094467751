ul.itemDesign {
    padding: 20px;
}

ul.itemDesign li {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #B5B5B5;
    opacity: 1;
    padding: 7px 13px 6px 10px;
    margin: 10px auto;
    border-radius: 5px;
    cursor: pointer;
}
ul.itemDesign li span {
text-align: left;
font: normal normal 300 14px/12px Montserrat;
letter-spacing: 0px;
color: #353535;
opacity: 1;
margin-bottom: 0;
    margin-left: 20px;
}
ul.itemDesign .fa {
    background: #CBC9C9 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50px;
    color: #fff;
    padding: 2px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}