
  #OmbreRightSidebar .typeOmbre input{
display:none
}
#OmbreRightSidebar .typeOmbre{
    position: relative;
    
}
#OmbreRightSidebar .btntype {
position: relative;
    width: 26px;
    height: 26px;
    border: 1px solid #707070;
    border-radius: 5px;

}
#OmbreRightSidebar .btntype.insettype:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    height: 18px;
    width: 18px;
    box-shadow: inset 0px 0px 5px grey;
    border-radius: 5px;
}
#OmbreRightSidebar .titleSize {
    font: normal normal normal 12px/15px Montserrat;
}
#OmbreRightSidebar   #OmbreRightSidebar label.form-check-label {
    font: normal normal normal 12px/15px Montserrat;
}
#OmbreRightSidebar .btntype.outsettype:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    height: 18px;
    width: 18px;
    box-shadow:  0px 0px 5px grey;
    border-radius: 5px;
}
#OmbreRightSidebar .RadioInput[type="radio"]+label {
    cursor: pointer;;
    
    border-radius: 5px;

    }
    #OmbreRightSidebar .RadioInput[type="radio"]:checked+label {
        box-shadow: none;
        background: #B5B5B5;
    }
    #OmbreRightSidebar .input-with-label-pixel {
    position: relative;
 
}
#OmbreRightSidebar  .input-small-pixel {
    border-radius:10px;
    text-align: center;

 }
 #OmbreRightSidebar  span.pixel-label {
    position: absolute;
    top: 5px;
    right: 20px;
    font-weight: 400;
    font-size: 12px;
    color: rgb(0 0 0 / 57%);
}
#OmbreRightSidebar .form-check-input:checked {
    background-color: #90E192;
    border-color: #90E192;
}
#OmbreRightSidebar .form-switch .form-check-input {
    background-image: url("../../../../assets/imgBorder/switchform.svg")!important;

}
#OmbreRightSidebar  input.rangeInput.me-2 {
    width: 85px;
    height: 2px;
}
#OmbreRightSidebar  .input-small-pixel {
    border-radius: 10px;
    text-align: center;
    padding-left: 0;
    width: 70px;
}
#OmbreRightSidebar .rangeInput{
    background: linear-gradient(to right, #707070 0%, #707070 40%, #fff 40%, #fff 100%);
    border: solid 2px #707070;
    border-radius: 8px;
    height: 7px;
    width: 400px;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
  }
  #OmbreRightSidebar .rangeInput::-webkit-slider-thumb {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    -webkit-appearance: none;
    cursor: ew-resize;
    background: white;
    border:1px solid #707070
  }
