#ModalIcon .drop-zone {
    width: 100%;
    height: 560px;
     background: white; 
    background-position: center;
    border: 2px dashed #717171;
    border-radius: 15px;
    opacity: 1;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    color: gray;
}
#ModalIcon img.thumbnails {
    width: 80px;
    max-height: 100px;
    margin: 2px;
    border: 1px solid gray;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}
#ModalIcon  .gallery-item i.fa-trash-alt {
    position: absolute;
    top: 5px;
    right: 5px;
    color: red;
    cursor: pointer;
    background: white;
    display:none
}
#ModalIcon .nav-link.tt.active {
    color: #f6c344;
}
#ModalIcon .nav-link.tt.active, #ModalIcon .nav-link.tt {
    font-size: 14px;
    background: transparent;
    border: none;
}
div#ModalIcon .btns-action {
    display: flex;
    justify-content: center;
    align-items: center;
}
#ModalIcon button.btn-closes {
    background: transparent;
    box-shadow: none;
    border: none;
    color: #eee;
}
#ModalIcon .nav-link.tt {
    color: #eee;
}
#ModalIcon  span.d-block.supplien {
    color: #b73636;
    font-weight: 400;
    font-size: 16px;
}
#ModalIcon  .iconList .icon {
    font-size: 14px;
    width: 25px;
    text-align: center;
    height: 25px;
    padding: 4px;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 7px;
    cursor: pointer;
}
#ModalIcon  .iconList .icon:hover {
color:#f6c344
}
#ModalIcon  .modal-body {

    height: 700px;
}
#ModalIcon .iconList {
max-height: 600px;
overflow-y: scroll;
}
#ModalIcon .gallery-item i.fa-check-circle {
    position: absolute;
    top: 5px;
    left: 5px;
    color: #098201;
    cursor: pointer;
    background: white;
}
#ModalIcon  .gallery-item:hover i {

    display:block
}
#ModalIcon  input#inputId {
    padding: 5px;
    border-radius: 8px;
    border: 1px solid rgb(219 219 219);
    width: 100%;
    margin: 10px 0px;
}
span.tag-wrapper.ReactTags__tag {
    background: #353535;
    color: white;
    padding: 3px 5px;
    display: inline-block;
    margin: 4px 4px;
    border-radius: 7px;
}
span.tag-wrapper.ReactTags__tag button {
    background: none;
    color: white;
    border: none;

}