#MenuItem {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 2px #00000029;
  opacity: 1;
  padding: 0px 13px;
  border-radius: 5px;
  cursor: grab;
}
#MenuItem .SectionAdd {
  display: block;
  margin: 25px 18px 0px 0%;
  text-align: right;
}
#MenuItem span.Parent {
  text-align: left;
  font: normal normal 300 14px/12px Montserrat;
  letter-spacing: 0px;
  color: #353535;
  opacity: 1;
  max-width: -moz-fit-content;
  max-width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 6px 0;
  float: none;
  width: 237px;
  display: block;
}
#MenuItem .actionclikpage {
  width: 100%;

}
#RenamePage .modal-header {
  background: #ffffff;
  padding: 13px;
  border-radius: 0px;
  border: none;
}
#RenamePage .modal-header .btn-close {

  font-size: 12px;
}
#RenamePage .modal-title.h4 {
  background: transparent;
  font: normal normal 600 21px/30px Montserrat;
  letter-spacing: 0px;
  color: #353535;
  font-size: 16px;
  font-weight: 500;
  text-align: justify;
}
.actionclikpage img {
  float: inline-start;
  margin: 4px 0;
}
img.btn-closeModal {
  cursor: pointer;
  margin: -42px 23px 2px 0;
}
.is-dragging {
  cursor: grabbing;
}

element.style {
  display: block;
  padding-left: 0px;
}
.fade.modalSeo.modal.show {
  background: rgb(39 40 34 / 65%);
}
ul.dropdown-menu.optionpages {
  transform: none !important;
  left: 107% !important;
  top: -45px !important;
  min-width: 191px;
  min-height: 109px;
  border: none;
  background-size: 100% 100% !important;
  box-shadow: 0px 0px 5px #80808057;
}
ul.dropdown-menu.optionpages.show li {
  font: normal normal 300 12px/12px Montserrat;
  letter-spacing: 0px;
  color: #353535;
  opacity: 1;
  text-align: left;
  margin: 9px 20px 9px 20px;
  display: block;
  cursor: pointer;
}
ul.dropdown-menu.optionpages.show:before {
  content: "";
  background: url("../../../assets//imgMenuSort/arrow-left.png") no-repeat !important;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 49px;
  right: 183px;
}
.SectionAdd {
  display: block;
  margin: 25px 18px 0px 0%;
  text-align: right;
}
ul.masquerOptions input {
  display: inline;
  visibility: hidden;
  width: 0px;
}
ul.masquerOptions label {
  display: inline-block;
  padding: 10px;
  width: 100%;
  cursor: pointer;
}
#MenuItem ul.masquerOptions label.jaune{
  color:#f6c344
}
#MenuItem ul.masquerOptions label.grise{
  color:#6c757d
}
div#MenuItem .is-drag-active {
  background: red;
}
#Menu select.form-select.title-selects {
  background: url("../../../assets/imgTitles/arrow-down.svg") no-repeat center
    right 10px;
  cursor: pointer;
  margin: 18px auto;
  font: normal normal normal 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #353535;
  opacity: 1;
}

.nestable-item-name {
  padding: 0px 17px 0 17px;
  margin-top: 12px;
}
#MenuItem li.masquerBtn:hover ul.masquerOptions:before {
  content: "";
  background: url("../../../assets//imgMenuSort/arrow-left.png") no-repeat !important;
  width: 20px;
  height: 30px;
  position: absolute;
  top: 14px;
  right: 191px;
  z-index: -1;
  transform: rotate(0deg);
}
#MenuItem li.masquerBtn:hover ul.masquerOptions {
  display: block;
  transform: translateX(148px);
}
#MenuItem li.masquerBtn ul.masquerOptions li.checkBox {
  margin: 0 auto;
}
#MenuItem ul.masquerOptions {
  position: absolute;
  transform: translate(148px, -40px) !important;
  min-width: 191px;
  background: #fff;
  min-height: 109px;
  padding: 0;
  border: none;
  background-size: 100% 100% !important;
  box-shadow: 0px 0px 5px #80808057;
  border-radius: 7px;
  display: none;
}
#MenuItem ul.masquerOptions li {
  padding: 0 !important;
}
#MenuItem ul.masquerOptions i {
  margin-right: 5px;
  font-size: 14px;
  vertical-align: middle;
  text-align: center;
}
#MenuItem ul.dropdown-menu.optionpages.show li {
  font: normal normal 300 12px/12px Montserrat;
  letter-spacing: 0px;
  color: #353535;
  opacity: 1;
  text-align: left;
  margin: 9px 20px 9px 20px;
  display: block;
  cursor: pointer;
}
#MenuItem ul.masquerOptions label {
  display: inline-block;
  padding: 9px;
  cursor: pointer;
  font: normal normal 300 11px/11px Montserrat;
  letter-spacing: 0px;
  color: #353535;
  opacity: 1;
  text-align: left;
}
#MenuItem ul.dropdown-menu.optionpages {
  transform: none !important;
  left: 98% !important;
  top: -41px !important;
  min-width: 191px;
  min-height: 141px;
  border: none;
  background-size: 100% 100% !important;
  box-shadow: 0px 0px 5px #80808057;
}
#Menu button#dropdownMenuButton1 {
  position: relative;
  padding: 7px 5px;
  background: transparent;
  border: none;
}
#Menu .titlechildren li.ItemMenu {
  padding: 13px 0px 1px 17px;
}
#Menu .titlechildren ul.MenuDrop {
  padding: 0;
}
#Menu ul.MenuDrop .option button {
  box-shadow: none;
  padding: 3px;
}
#Menu button.btnpage {
  background: #f6c344 0% 0% no-repeat padding-box;
  border-radius: 7px;
  border: none;
  padding: 9px;
  font: normal normal normal 12px/12px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  width: 182px;
}
#Menu .modal-header {
  min-height: 111px;
  background: #717171 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px #00000029;
  border-radius: 10px 10px 0px 0px;
}
#Menu span#exampleModalLabel {
  margin-left: 31px;
  background: transparent;
  text-align: justify;
  font: normal normal normal 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #cbc9c9;
  opacity: 1;
}
#Menu .modal-content {
  border-radius: 10px;
}
.modal-backdrop.fade.show {
  display: none !important;
}
#Menu .modal-dialog.modal-lg {
  top: 14%;
}
#Menu button.btn.btn-secondary {
  width: 140px;
  background: #b5b5b5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border: none;
  padding: 8px 0px;
}
#Menu button.btn.btn-primary {
  width: 140px;
  background: #f6c344 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 7px;
}

#Menu select.form-select.NamePage {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  opacity: 1;
  margin: 11px auto;
}

#Menu select::placeholder {
  text-align: left;
  font: normal normal medium 10px/18px Montserrat;
  letter-spacing: 0px;
  color: #353535;
  opacity: 1;
}

.indexationtitre {
  padding-left: 0;
}

.modalSeo .titre_popup_page {
  /* background: #f9f9f9; */
  padding: 13px;
  border-radius: 11px 11px 0px 0px;
}
.modalSeo .modal_header_add_page {
  background: transparent;
  font: normal normal 600 21px/30px Montserrat;
  letter-spacing: 0px;
  color: #353535;
  text-align: justify;
}
.modalSeo .add_page_modal_content .addPageOptions {
  width: 100%;
  padding-right: 0;
}
.modalSeo .add_page_modal_content .addPageOptions .add_Page_Options_title {
  display: block;
  text-align: justify;
  font: normal normal normal 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #353535;
  margin: 0 auto;
}
.modalSeo .add_page_modal_content {
  padding: 15px;
}

.modalSeo .modal_header_add_page i {
  position: absolute;
  right: 23px;
  top: 24px;
  color: #fff;
  cursor: pointer;
}
.modalSeo .titre_popup_add_page {
  margin-left: 15px;
  color: #fff;
}
.modalSeo div#addPageModal .modal-body {
  background: transparent;
  padding: 17px;
}
.modalSeo button.btn.btn-rounded.btn-outline-secondary {
  text-align: center;
  font: normal normal bold 13px/16px Montserrat;
  letter-spacing: 0px;
  border-radius: 11px;
}
.modalSeo button.btn.btn-rounded.btn.btn-outline-secondary.delete {
  background: #f6c344;
  text-align: center;
  font: normal normal bold 13px/16px Montserrat;
  letter-spacing: 0px;
  color: #fff;
  border-color: #f6c344;
}
.modalSeo button.btn-outline-secondary:hover {
  background-color: #fff;
  color: #6c757d;
  border-color: #6c757d;
}
.modalSeo span.NameSiteSeo {
  color: #f6c344;
}
#MenuItem ul.dropdown-menu.optionpages.show li.green {
  color: #3ed917;
  font-weight: bold;
}
#MenuItem ul.dropdown-menu.optionpages.show li.orange {
  color: #d99d17;
  font-weight: bold;
}
#MenuItem ul.dropdown-menu.optionpages.show li.red {
  color: red;
  font-weight: bold;
}
#MenuItem ul.dropdown-menu.optionpages.links {
  transform: none !important;
  left: 98% !important;
  top: -26px !important;
  min-width: 191px;
  min-height: 59px;
  border: none;
  background-size: 100% 100% !important;
  box-shadow: 0px 0px 5px #80808057;
}
ul.dropdown-menu.optionpages.show.links:before {
  content: "";
  background: url("../../../assets//imgMenuSort/arrow-left.png") no-repeat !important;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 17px;
  right: 183px;
}
#MenuItem input[name="hidden"]:checked + label {
  color: #f6c344 !important;
}
ul.masquerOptions input {
  display: inline;
  visibility: visible !important;
  width: 15px !important;
}
#MenuItem li.masquerBtn ul.masquerOptions li.checkBox {
  margin: 0 auto;
  padding: 5px 3px!important;
}
