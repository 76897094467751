html body {

    box-sizing: border-box;
    padding: 0px !important;
    margin: 0px !important;
    overflow-x: hidden;
    width: 100%;
    font-family: "Montserrat"!important;
    font-weight:100;
    background: #F9F9F9 0% 0% no-repeat padding-box;
    opacity: 1;
}


@media (max-width: 1023px) {
    img.rotate-tablette {
        display: block;
        width: 66px;
        text-align: center;
        margin: 24px auto;
    }
.content-mobile {
    text-align: center;
    font: normal normal normal 15px/15px Montserrat;
    letter-spacing: 0px;
    color: #AAAAAA;
    opacity: 1;
    margin: 5% auto;
  }
  .content-mobile {
    display: block;
  }
  .content-desk {
      display: none;
  }
}
@media (min-width: 1024px) {  
    .content-mobile {
        display: none;
    }
    }
@media (min-width: 1200px) {  
.content-mobile {
    display: none;
}
}
@media (min-width: 1360px) {  
    .content-mobile {
        display: none;
    }
    }
    @media (min-width: 1920px) {  
        .content-desk {
            display: block;
        }
        .content-mobile {
        display: none;
    }
}