.modal-body {
  padding: 5px 0;
}
.position-relative.sliders-item {
  position: relative;
}
div#ModalGallery {
  width: 60%;
  max-width: 1024px;
}

.galleryConfig {
  padding: 10px;
}
#ModalGallery .drop-zone {
  width: 100%;
  height: 92px;
  margin-bottom: 15px;
  background: white;
  background-position: center;
  border: 2px dashed #717171;
  border-radius: 15px;
  opacity: 1;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  color: gray;
}
#ModalGallery .thumbnails {
  width: 80px;
  height: 80px;
  background-size: cover !important;
  background-position: center !important;
  max-height: 100px;
  margin: 2px;
  border: 1px solid gray;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
input.radius-input.d-block {
  width: 100%;
}
.back-icon {
  font-size: 25px;
  margin-right: 10px;
  cursor: pointer;
}
#ModalGallery .biblio_list {
  max-height: 400px;
  overflow-y: scroll;
}
#ModalGallery .slider_list {
  max-height: 500px;
  overflow: scroll;
}
#ModalGallery .gallery_list {
  max-height: 600px;
  overflow-y: scroll;
}
#ModalGallery .sliders-item i.fa-trash-alt {
  position: absolute;
  top: 5px;
  right: 5px;
  color: red;
  cursor: pointer;
  background: white;
  display: none;
}

#ModalGallery .sliders-item i.fa-check-circle {
  position: absolute;
  top: 5px;
  left: 5px;
  color: #098201;
  cursor: pointer;
  background: white;
}
#ModalGallery .sliders-item:hover i {
  display: block;
}
#ModalGallery input#inputId {
  padding: 5px;
  border-radius: 8px;
  border: 1px solid rgb(219 219 219);
  width: 100%;
  margin: 10px 0px;
}
span.tag-wrapper.ReactTags__tag {
  background: #353535;
  color: white;
  padding: 3px 5px;
  display: inline-block;
  margin: 4px 4px;
  border-radius: 7px;
}
span.tag-wrapper.ReactTags__tag button {
  background: none;
  color: white;
  border: none;
}
