.Widgets .border {
    content: '';
    border: 0.5px var(--bs-border-style) #b5b5b5 !important;
    border-top: none !important;
    width: 241px;
    height: 0px;
    display: block;
    position: absolute;
    z-index: 0;
    left: 97px;
    top: 30px;
}

.Widgets .border.pop {
    width: 217px;
    left: 122px;
}

.Widgets .accordion-header {
    margin-bottom: 0;
    --bs-accordion-border-color: none;
}

.Widgets .accordion-item {
    border: 0px solid transparent;
}

#widgetsPanel .gjs-blocks-cs {
    max-width: 397px;
}

.Widgets .accordion-button:focus {
    z-index: 3;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}

.Widgets .accordion-body {
    text-align: justify;
}

.Widgets .accordion-button:not(.collapsed)::after {
    background-image: none;
    display: flex;
    justify-content: end;
    align-items: end;
}

.Widgets .accordion-button::after {
    margin-left: auto;
    background-image: none;
    content: "\f106";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    background-repeat: no-repeat;

    margin-top: 3%;
}

.Widgets h2.accordion-header button {
    background: transparent;
    font: normal normal normal 12px/12px Montserrat;
    letter-spacing: 0px;
    color: #717171;
    opacity: 1;
    padding: 15px 10px;
    max-width: 380px;
}

div#v-pills-widgets div#widgetsPanel {
    max-height: 756px;
    overflow: scroll;
}

.block-filter {
    margin: 0px 2px;
    width: 90%;
    padding: 8px 40px 8px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.block-filter::after {
    content: '\f002';
    /* Unicode for Font Awesome search icon */
    font-family: 'Font Awesome 5 Free';
    /* Font Awesome font family */
    font-weight: 900;
    font-size: 20px;
    color: #aaa;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
}