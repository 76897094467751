.cursor{
    cursor: pointer;
}
.Allpermission {
    font-size: 14px;
    list-style: none;
    width: 100%;
    padding: 20px 25px;
    border: 1px solid #e4e4e4;
    background-color: white;
}