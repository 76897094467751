div#Modelpernoalise .templatePane .Modelssites {
    height: 575px;
}

img.captureModel {
    object-fit: cover;
    height: 27px;
    max-width: 476px
}
div#Modelpernoalise .tempList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin-top: 130px;
    margin-left: 85px;
    overflow: hidden;
    overflow-y: scroll;
    height: 578px;
}

div#Modelpernoalise .templatePane .Modelssites {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 4px #00000029;
    opacity: 1;
    padding: 2px;
    border-radius: 20px;
    margin: 5% auto;
    height: auto;
}
div#Modelpernoalise .item .capturetemplate {
    display: block;
    width: 100%;
    height: 69px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}
div#Modelpernoalise .item.blank:hover .show-action {
    display: inherit;
    position: absolute;
    background: #33333333 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    opacity: 1;
    height: 134px;
    align-items: center;
    top: 0%;
    justify-content: center;
    width: 300px;
    transition: background-color 2s ease-in, opacity 2s ease-in;
}

div#Modelpernoalise .item {
    width: 300px;
    height: 146px;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    margin: 30px 30px 30px 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

div#Modelpernoalise .optiontemp div.dropdown-menu.show {
    margin: 5px !important;
    font-size: 14px;
    inset: auto auto 0px 0px !important;
    width: 151px !important;
    transform: translate(-4.55556px, 4.22222px) !important;
    height: 134px;
    border: 0px solid rgba(0, 0, 0, 0.15);
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px 0px 0px 0px;
    opacity: 1;
}