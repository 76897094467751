#EspacementColonne .titleEspacement span {
    font: normal normal normal 12px/15px Montserrat;
    color: white;
    letter-spacing: 0px;
    color: white;
    opacity: 1;
    display: block;
    text-align: left;
    margin-left: 6px;
}
#EspacementColonne div#marginblock {
    justify-content: space-around;
}
#EspacementColonne .espacement_inputs {
    padding: 3px;
    border-radius: 14px;
    width: 317px;
    height: 204px;
    margin: 40px auto 0 auto;
    position: relative;
    background: #717171 0% 0% no-repeat padding-box;
    border: 1px solid #B5B5B5;
    opacity: 1;
}
#EspacementColonne .spacing {
    width: 189px;
    height: 107px;
    background: #717171 0% 0% no-repeat padding-box;
    border: 1px solid #B5B5B5;
    opacity: 1;
    border-radius: 4px;
    position: relative;
}
#EspacementColonne div#marginblock:before, div#paddingblock:before {
    background: #eee;
    border-radius: 5px;
    color: #6c757d;
    line-height: 2px;
    padding: 0 6px;
    position: absolute;
    top: -1px;
}
#EspacementColonne div#marginblock:before, div#paddingblock:before {
    background: #eee;
    border-radius: 5px;
    color: #6c757d;
    line-height: 2px;
    padding: 0 6px;
    position: absolute;
    top: -1px;
}

#EspacementColonne .spacintInput {
    border-radius: 5px;
    box-shadow: none;
    margin: 11px 6px 21px 2px;
    max-width: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #71717175;
    opacity: 1;
}
#EspacementColonne #marginblock input::placeholder {
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #717171;
    opacity: 1;
    text-align: center;
}

#EspacementColonne .spacing-center-bottom{
    position: relative; 
}
#EspacementColonne input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  #EspacementColonne input[type=number] {
    text-align: center;
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    width: 40px;
    background: #353535 0% 0% no-repeat padding-box;
}

#EspacementColonne span.margin {
    position: absolute;
    top: -15px;
    left: 11px;
    width: 58px;
    text-align: center;
    border-radius: 7px 8px 0px 0px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CBC9C9;
    font: normal normal normal 10px/13px Montserrat;
    letter-spacing: 0px;
    color: #717171;
    opacity: 1;
}
#EspacementColonne .paddingstype{
    position: relative;
}
#EspacementColonne span.padding {
    position: absolute;
    text-align: center;
    top: -13px;
    left: 5px;
    width: 58px;
    opacity: 1;
    border-radius: 7px 8px 0px 0px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CBC9C9;
    font: normal normal normal 10px/13px Montserrat;
    letter-spacing: 0px;
    color: #717171;
}
#EspacementColonne .btntype.seperatetype i.fal {
    color: #fff;
}